<template>
    <Navbar @show-login="dialog = true" :loginState="loginState"/>
    <v-app :style="{ background: $vuetify.theme.themes.dark.background}">
        <v-container id="home" class="bg-home" fluid>
            <v-row >
                <v-col cols="6">
                    <h1 class="left top black-text" >Renting a house on Bread Rent!</h1>
                    <br />
                    <h3 class="left dark-red-text">Buy and sell second-hand housing</h3>
                    <br />
                    <br />
                    <br />
                    <v-text-field
                        class="search left"
                        label="Enter address, MLS@"
                        v-model="searchContent"
                    >
                    </v-text-field>
                    <v-btn 
                        class="left" 
                        tile 
                        color="#A7121D" 
                        dark 
                        @click="this.$router.push({name: 'search', query: {'searchText': this.searchContent}})"
                        >
                       <div class="white-text">Search</div> 
                    </v-btn>
                </v-col>
                <v-col cols="6">
                    <v-card
                        class="mx-auto top"
                        max-width="500"
                        >
                        <v-card-title class="text-h6 font-weight-regular justify-space-between">
                        <span>{{ currentTitle }}</span>

                        </v-card-title>

                        <v-window v-model="step">
                            <v-window-item :value="1">
                                <v-card-text>
                                    <v-text-field
                                        label="Email"
                                        placeholder="john@google.com"
                                        v-model="registerEmail"
                                        >
                                    </v-text-field>
                                    <span class="text-caption text-grey-darken-1">
                                        The email address you need to log in as your account
                                    </span>
                                </v-card-text>
                            </v-window-item>

                            <v-window-item :value="2">
                                <v-card-text>
                                    <v-text-field
                                        label="Password"
                                        type="password"
                                        v-model="registerPassword"
                                        >
                                    </v-text-field>
                                    <v-text-field
                                        label="Confirm Password"
                                        type="password"
                                        v-model="registerPasswordConfirm"
                                        >
                                    </v-text-field>
                                    <span class="text-caption text-grey-darken-1">
                                        Please set your password
                                    </span>
                                </v-card-text>
                            </v-window-item>

                            <v-window-item :value="3">
                                <div class="pa-4 text-center">
                                <h3 class="text-h6 font-weight-light mb-2">
                                    {{ this.welcomeOrFailed }}
                                </h3>
                                <span class="text-caption text-grey">{{ this.registerMessage }}</span>
                                </div>
                            </v-window-item>
                        </v-window>

                        <v-divider></v-divider>

                        <v-card-actions>
                        <v-btn
                            v-if="step > 1"
                            variant="text"
                            @click="step--"
                        >
                            Back
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn
                            v-if="step < 3"
                            color="#A7121D"
                            variant="flat"
                            @click="onRegisterNext"
                        >
                            <div style="color: #ffffff">Next</div>
                        </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
        <v-container id="sale">
            <br />
            <br />
            <v-col class="sale_content-tex" align="center" justify="center">
                <strong ><h2>Recommended for sale</h2></strong>
                <h2 class="dark-red-text"><strong>___________</strong></h2>
            </v-col>
            <br />
            <v-row no-gutters>
                <v-col
                    v-for="condo in condos"
                    :key="condo.mlNum"
                    cols="12"
                    sm="3"
                    >
                    <HomeSale :condo="condo"/>
                </v-col>
            </v-row>
            <v-col class="sale_content-tex" align="center" justify="center">
                <v-btn tile color="#A7121D" dark @click="this.$router.push('/maplist')">
                       <div class="white-text">See more...</div> 
                </v-btn>
            </v-col>
        </v-container>
        <v-footer
            class="bg-indigo-lighten-1 text-center d-flex flex-column"
            >
            <div>
                <v-btn
                    v-for="icon in icons"
                    :key="icon"
                    class="mx-4"
                    :icon="icon"
                    variant="text"
                    >
                </v-btn>
            </div>

            <v-divider></v-divider>
            <div>
                {{ new Date().getFullYear() }} — <strong>Canadian real estate</strong>
            </div>
        </v-footer>
        <v-dialog
                        v-model="dialog"
                        max-width="400"
                        persistent
                        >
                        <LoginDialog @hide-login="dialog = false" @do-login="login"/>
        </v-dialog>  
    </v-app>
</template>

<script>
    import Navbar from '@/components/Navbar'
    import HomeSale from '@/components/HomeSale'
    import LoginDialog from '@/components/LoginDialog'

    export default {
        name: 'Home',
        methods: {
            onRegisterNext() {
                if(this.step < 2) {
                    this.step++
                    return
                }
                if((this.registerPassword == '' || this.registerPasswordConfirm == '') || (this.registerPassword != this.registerPasswordConfirm)) {
                    console.log("两次输入密码不匹配")
                    return
                }
                fetch('http://118.195.179.89:8100/api/bread/landing/member/register', {
                    method: 'post',
                    headers: {'Content-type': 'application/json'},
                    body: JSON.stringify({
                        username: this.registerEmail,
                        password: this.registerPassword,
                    })
                })
                .then(res => res.json())
                .then((response) => {
                        if(response.code === -1) {
                            console.log('注册失败')
                            this.welcomeOrFailed = 'Registration Failed'
                            this.registerMessage = 'Error: ' + response.msg
                            this.step++
                            return
                        }
                        this.welcomeOrFailed = 'Welcome to Bread Rent'
                        this.registerMessage = 'Thanks for registering!'
                        this.step++
                })
            },
            login(email, password) {
                fetch('http://118.195.179.89:8100/api/bread/landing/member/login', {
                    method: 'post',
                    headers: {'Content-type': 'application/json'},
                    body: JSON.stringify({
                        username: email,
                        password: password,
                    })
                })
                .then(res => res.json())
                .then((response) => {
                    if(response.code === -1) {
                        console.log('用户名密码错误')
                        return
                    }
                    localStorage.setItem('bread-token', response.data.token)
                    this.dialog = false
                    this.loginState = true
                })
            },
        },
        computed: {
            currentTitle () {
                switch (this.step) {
                case 1: return 'Register '
                case 2: return 'Set your password '
                default: return 'Success '
                }
            },
        },
        data() {
            return {
                welcomeOrFailed: '',
                registerMessage: '',
                registerEmail: '',
                registerPassword: '',
                registerPasswordConfirm: '',
                searchContent: '',
                loginState: false,
                dialog: false,
                step: 1,
                condos:[
                    {
                            mlNum: 'C5625246',
                            addr: '115 Mcmahon Dr',
                            lpDol: '645,000',
                            items: [
                                {
                                    src: 'http://www.breadrent.com/CondoMedia/C5625246/1.jpeg',
                                },
                                {
                                    src: 'http://www.breadrent.com/CondoMedia/C5625246/2.jpeg',
                                },
                                {
                                    src: 'http://www.breadrent.com/CondoMedia/C5625246/3.jpeg',
                                },
                                {
                                    src: 'http://www.breadrent.com/CondoMedia/C5625246/4.jpeg',
                                },
                                {
                                    src: 'http://www.breadrent.com/CondoMedia/C5625246/5.jpeg',
                                },
                                {
                                    src: 'http://www.breadrent.com/CondoMedia/C5625246/6.jpeg',
                                },
                                {
                                    src: 'http://www.breadrent.com/CondoMedia/C5625246/7.jpeg',
                                },
                                {
                                    src: 'http://www.breadrent.com/CondoMedia/C5625246/8.jpeg',
                                },
                            ]
                    }, 
                    {
                            mlNum: 'S5598774',
                            addr: '58 Lakeside Terr',
                            lpDol: '549,000',
                            items: [
                                {
                                    src: 'http://www.breadrent.com/CondoMedia/S5598774/1.jpeg',
                                },
                                {
                                    src: 'http://www.breadrent.com/CondoMedia/S5598774/2.jpeg',
                                },
                                {
                                    src: 'http://www.breadrent.com/CondoMedia/S5598774/3.jpeg',
                                },
                                {
                                    src: 'http://www.breadrent.com/CondoMedia/S5598774/4.jpeg',
                                },
                            ]
                    }, 
                    {
                            mlNum: 'C5579580',
                            addr: '1 Avondale Ave',
                            lpDol: '788,000',
                            items: [
                                {
                                    src: 'http://www.breadrent.com/CondoMedia/C5579580/1.jpeg',
                                },
                                {
                                    src: 'http://www.breadrent.com/CondoMedia/C5579580/2.jpeg',
                                },
                                {
                                    src: 'http://www.breadrent.com/CondoMedia/C5579580/3.jpeg',
                                },
                                {
                                    src: 'http://www.breadrent.com/CondoMedia/C5579580/4.jpeg',
                                },
                            ]
                    }, 
                    {
                            mlNum: 'C5624763',
                            addr: '455 Front St E',
                            lpDol: '599,000',
                            items: [
                                {
                                    src: 'http://www.breadrent.com/CondoMedia/C5624763/1.jpeg',
                                },
                                {
                                    src: 'http://www.breadrent.com/CondoMedia/C5624763/2.jpeg',
                                },
                                {
                                    src: 'http://www.breadrent.com/CondoMedia/C5624763/3.jpeg',
                                },
                                {
                                    src: 'http://www.breadrent.com/CondoMedia/C5624763/4.jpeg',
                                },
                            ]
                    }, 
                    {
                            mlNum: 'C5626010',
                            addr: '15 Stafford St',
                            lpDol: '19,650',
                            items: [
                                {
                                    src: 'http://www.breadrent.com/CondoMedia/C5626010/1.jpeg',
                                },
                                {
                                    src: 'http://www.breadrent.com/CondoMedia/C5626010/2.jpeg',
                                },
                                {
                                    src: 'http://www.breadrent.com/CondoMedia/C5626010/3.jpeg',
                                },
                                {
                                    src: 'http://www.breadrent.com/CondoMedia/C5626010/4.jpeg',
                                },
                            ]
                    }, 
                    {
                            mlNum: 'C5626433',
                            addr: '60 Tannery Rd',
                            lpDol: '759,000',
                            items: [
                                {
                                    src: 'http://www.breadrent.com/CondoMedia/C5626433/1.jpeg',
                                },
                                {
                                    src: 'http://www.breadrent.com/CondoMedia/C5626433/2.jpeg',
                                },
                                {
                                    src: 'http://www.breadrent.com/CondoMedia/C5626433/3.jpeg',
                                },
                                {
                                    src: 'http://www.breadrent.com/CondoMedia/C5626433/4.jpeg',
                                },
                            ]
                    }, 
                    {
                            mlNum: 'W5568364',
                            addr: '320 Dixon Rd',
                            lpDol: '424,900',
                            items: [
                                {
                                    src: 'http://www.breadrent.com/CondoMedia/W5568364/1.jpeg',
                                },
                                {
                                    src: 'http://www.breadrent.com/CondoMedia/W5568364/2.jpeg',
                                },
                                {
                                    src: 'http://www.breadrent.com/CondoMedia/W5568364/3.jpeg',
                                },
                                {
                                    src: 'http://www.breadrent.com/CondoMedia/W5568364/4.jpeg',
                                },
                            ]
                    }, 
                    {
                            mlNum: 'E5573103',
                            addr: '1359 Neilson Rd',
                            lpDol: '695,000',
                            items: [
                                {
                                    src: 'http://www.breadrent.com/CondoMedia/E5573103/1.jpeg',
                                },
                                {
                                    src: 'http://www.breadrent.com/CondoMedia/E5573103/2.jpeg',
                                },
                                {
                                    src: 'http://www.breadrent.com/CondoMedia/E5573103/3.jpeg',
                                },
                                {
                                    src: 'http://www.breadrent.com/CondoMedia/E5573103/4.jpeg',
                                },
                            ]
                    }, 
                ],
                icons: [
                    'mdi-facebook',
                    'mdi-twitter',
                    'mdi-linkedin',
                    'mdi-instagram',
                ],
                cards: [
                    1,2,3,4
                ]
            }
        },
        components: {
            Navbar,
            HomeSale,
            LoginDialog,
        },
    }
</script>

<style>
.sale_content-text {
    margin-top: 8px;
}
.dark-red-text {
    color: #A7121D;
}
.white-text {
    color: #ffffff;
}
.black-text {
    color: #181818
}
.footer-bg {
    background: linear-gradient(
        to right,
        #A7121D,
        #A7121D 50%,
        #A7121D 50%,
        #A7121D 50%,
    );
}
.bg-home {
  background: linear-gradient(
      180deg,
      rgba(246, 246, 246, 0.5) 1%,
      rgba(246, 246, 246, 0.274) 51.03%,
      rgba(248, 248, 248, 0.5) 100%
    ),
    url("../assets/toronto-bg.png");
  height: 80vh !important;
  background-size: cover;
}
.bg-sale {
  background: linear-gradient(
      180deg,
      rgba(246, 246, 246, 0.5) 1%,
      rgba(246, 246, 246, 0.274) 51.03%,
      rgba(248, 248, 248, 0.5) 100%
    ),
    url("../assets/home-sale-bg.png");
  height: 100vh !important;
  background-size: cover;
}
.bg2 {
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.5) 1%,
      rgba(0, 0, 0, 0.273638) 51.03%,
      rgba(0, 0, 0, 0.5) 100%
    ),
    url("../assets/toronto-bg.png");
  height: 100vh !important;
  background-size: cover;
}
.top {
    margin-top: 200px;
}
.left {
    margin-left: 180px;
}
.search {
    width: 600px;
    background: linear-gradient(
        to right,
        #ffffff,
        #ffffff 50%,
        #ffffff 50%,
        #ffffff 50%,
    );
}
.firstCard {
    width: 100%;
    height: 600px;
    background: linear-gradient(
        to right,
        #181818,
        #181818 50%,
        #111111 50%,
        #111111 50%,
    );
    text-align: center;
    padding: 2rem 2rem;
}
</style>