<template>
    <div class="text-center">
        <v-menu v-model="menu" :close-on-content-click="false" location="end">
            <template v-slot:activator="{ props }">
                <v-btn color="indigo" v-bind="props">
                    更多筛选
                </v-btn>
            </template>

            <!-- 更多筛选打开的内容 -->
            <v-card width="800">
                <v-list>
                    <v-list-item>
                        <v-row align="center">
                            <v-col cols="2">
                                <p style="font-size: 15px; font-weight: bold; text-align: center; ">上市天数</p>
                            </v-col>
                            <v-col cols="9"> <v-btn-toggle v-model="toggle">
                                    <v-btn value="all">不限</v-btn>
                                    <v-btn value="0">今日上市</v-btn>
                                    <v-btn value="3">3天内</v-btn>
                                    <v-btn value="7">7天内</v-btn>
                                    <v-btn value="14">14天内</v-btn>
                                    <v-btn value="30">30天内</v-btn>
                                </v-btn-toggle></v-col>
                        </v-row>

                    </v-list-item>

                    <v-list-item>
                        <v-switch v-model="openHouse" color="blue" label="Open House" hide-details></v-switch>
                    </v-list-item>
                    <v-list-item>
                        <v-switch v-model="priceChange" color="blue" label="价格变动" hide-details></v-switch>
                    </v-list-item>
                    <v-list-item>
                        <v-row>
                            <v-col cols="3"> <v-switch v-model="noManagementFee" color="blue" label="无管理费" hide-details></v-switch>
                            </v-col>
                            <v-col cols="6">
                               <span style="font-size: 15px; font-weight: bold; text-align: left; ">管理费上限 </span> <v-text-field label="请输入">
                                </v-text-field>
                                
                            </v-col>

                        </v-row>


                    </v-list-item>
                </v-list>

                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn color="blue" variant="text" @click="menu = false">
                        清除
                    </v-btn>
                    <v-btn color="blue" variant="tonal" @click="menu = false">
                        确认
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-menu>
    </div>
</template>

<script>
   export default {
       name: 'MoreFilter',
       data() {
        return {
            menu: false,
            message: false,
            openHouse: false,
            priceChange:false,
            noManagementFee:false,
            toggle: "all",
        }
       }
   }
</script>