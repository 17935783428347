<template>
    <NavBar />
    <!-- <MapListSelectedBar class="selected-bar-top"/> -->
    <v-row class="selected-bar-top">
        <v-col
            cols="3"
            >
            <v-card
                color="#ff000000"
                flat
    
                >
                <v-card-text>
                    <v-text-field
                        :loading="loading"
                        density="compact"
                        variant="solo"
                        label="MLS@, City, Addr"
                        single-line
                        hide-details
                        @click:append-inner="onClick"
                        @update:modelValue="onSearchContentChanged"
                        >
                    </v-text-field>
                </v-card-text>
                
            </v-card>
        </v-col>
        <v-col cols="8">
            <v-row>
                <v-btn
                    depressed
                    color="red-darken-2"
                    style="margin-top: 32px"
                    @click="searchMapData"
                    >
                    Search
                </v-btn>
                <v-col cols="2">
                    <v-select
                        class="selected-select"
                        :items="selecteWuye"
                        item-title="state"
                        item-value="value"
                        return-object
                        single-line
                        height=50
                        outline
                        label="Sale Or Lease"
                        density="compact"
                        bg-color="#ffffff"
                        @update:modelValue="onTypeChanged"
                        >
                    </v-select>
                </v-col>
                <!-- <v-col cols="2">
                    <v-select
                        class="selected-select"
                        :items="selecteState"
                        item-title="state"
                        item-value="value"
                        return-object
                        single-line
                        height=50
                        outline
                        label="类型"
                        density="compact"
                        bg-color="#ffffff"
                        >
                    </v-select>
                </v-col>
                <v-col cols="2">
                    <v-select
                        class="selected-select"
                        :items="selecteState"
                        item-title="state"
                        item-value="value"
                        return-object
                        single-line
                        height=50
                        outline
                        label="价格"
                        density="compact"
                        bg-color="#ffffff"
                        >
                    </v-select>
                </v-col>
                <v-col cols="2">
                    <v-select
                        class="selected-select"
                        :items="selecteState"
                        item-title="state"
                        item-value="value"
                        return-object
                        single-line
                        height=50
                        outline
                        label="卧室数量"
                        density="compact"
                        bg-color="#ffffff"
                        >
                    </v-select>
                </v-col>
                <v-col cols="2">
                    <v-select
                        class="selected-select"
                        :items="selecteState"
                        item-title="state"
                        item-value="value"
                        return-object
                        single-line
                        height=50
                        outline
                        label="浴室数量"
                        density="compact"
                        bg-color="#ffffff"
                        >
                    </v-select>
                </v-col>
                <v-col cols="2">
                    <v-btn
                        depressed
                        color="#ffffff"
                        class="selected-switch-btn"
                        @click="this.updateMapData()"
                        >
                        更多筛选
                    </v-btn>
                </v-col> -->
            </v-row>
        </v-col>
        <v-col cols="1">
            <v-btn
                depressed
                color="red-darken-2"
                class="selected-switch-btn"
                @click="showMap = !showMap"
                >
                {{ showMap ? switchBtnText1 : switchBtnText2 }}
            </v-btn>
        </v-col>
    </v-row>
    <v-row>
        <v-col :cols="showMap? listCol : listColFull">
            <v-card class="selected-card" border flat>
                <v-row class="selected-text-des">A total of<div style="color: #A7121D">{{ houseTotal }}</div> istings were found for you,，the current page only displays 50 listings</v-row>
                <v-row no-gutters>
                    <v-col
                        v-for="house, i in houses.length > 50 ? houses.slice(0, 50) : houses"
                        :key="i"
                        cols="12"
                        :sm="showMap? listSm : listSmFull"
                        >
                        <MapListHouseCard :house="house"/>
                    </v-col>
                </v-row>
            </v-card>
        </v-col>
        <v-col v-show="showMap" cols="8">
            <v-card class="selected-card">
                <div id="map"></div>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
// pk.eyJ1IjoibGlua2RvdmFraW4iLCJhIjoiY2xhaHgyZHJyMDMxazNubDdzZ3d6MzdkOCJ9.A4OA6XXM7bn3kpXdQ8hBcw
    import NavBar from '@/components/Navbar.vue'
    import MapListSelectedBar from '@/components/MapListSelectedBar'
    import mapboxgl from 'mapbox-gl'
    import MapListHouseCard from '@/components/MapListHouseCard.vue';
    
    
    export default {
        name: 'MapList',
        components: {
            NavBar,
            MapListSelectedBar,
            MapListHouseCard
        },
        methods: {
            createMap() {
                mapboxgl.accessToken =
                    "pk.eyJ1IjoibGlua2RvdmFraW4iLCJhIjoiY2xhaHgyZHJyMDMxazNubDdzZ3d6MzdkOCJ9.A4OA6XXM7bn3kpXdQ8hBcw";
                this.map = new mapboxgl.Map({
                    container: "map",
                    style: "mapbox://styles/mapbox/streets-v12",
                    center: [-79.386351051501805, 43.697831265018799],
                    zoom: 11,
                });
                this.map.on('load', () => {
                    this.map.setPaintProperty('building', 'fill-color', [
                        'interpolate',
                        // Set the exponential rate of change to 0.5
                        ['exponential', 0.5],
                        ['zoom'],
                        // When zoom is 15, buildings will be beige.
                        15,
                        '#D9D3C9',
                        // When zoom is 18 or higher, buildings will be yellow.
                        18,
                        '#ffd700'
                    ]);
                        
                    this.map.setPaintProperty('building', 'fill-opacity', [
                        'interpolate',
                        // Set the exponential rate of change to 0.5
                        ['exponential', 0.5],
                        ['zoom'],
                        // When zoom is 10, buildings will be 100% transparent.
                        10,
                        0.5,
                        // When zoom is 18 or higher, buildings will be 100% opaque.
                        18,
                        1
                    ]);
                    this.map.addSource('places', {
                        type: 'geojson',
                        data: this.geoJson,
                        cluster: true,
                        clusterMaxZoom: 14, // Max zoom to cluster points on
                        clusterRadius: 50
                    });

                    this.map.addLayer({
                        id: 'clusters',
                        type: 'circle',
                        source: 'places',
                        filter: ['has', 'point_count'],
                        paint: {
                            // Use step expressions (https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions-step)
                            // with three steps to implement three types of circles:
                            //   * Blue, 20px circles when point count is less than 100
                            //   * Yellow, 30px circles when point count is between 100 and 750
                            //   * Pink, 40px circles when point count is greater than or equal to 750
                            'circle-color': [
                                'step',
                                ['get', 'point_count'],
                                '#ff4d4f',
                                100,
                                '#f1f075',
                                750,
                                '#f28cb1'
                            ],
                            'circle-radius': [
                                'step',
                                ['get', 'point_count'],
                                15,
                                100,
                                30,
                                750,
                                40
                            ]
                        }
                    });
                    
                    this.map.addLayer({
                        id: 'cluster-count',
                        type: 'symbol',
                        source: 'places',
                        filter: ['has', 'point_count'],
                        layout: {
                            'text-field': ['get', 'point_count_abbreviated'],
                            'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
                            'text-size': 12,
                        }
                    });                    
                    this.map.addLayer({
                        'id': 'places',
                        'type': 'circle',
                        'source': 'places',
                        'filter': ['!', ['has', 'point_count']],
                        'paint': {
                            'circle-color': '#A7121D',
                            'circle-radius': 8,
                            'circle-stroke-width': 2,
                            'circle-stroke-color': '#ffffff'
                        }
                    });
                    const popup = new mapboxgl.Popup({
                        closeButton: false,
                        closeOnClick: false
                    });
                    this.map.on('mouseenter', 'places', (e) => {
                        this.map.getCanvas().style.cursor = 'pointer';
                        
                        const coordinates = e.features[0].geometry.coordinates.slice();
                        const description = e.features[0].properties.description;
                        
                        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
                        coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
                        }

                        popup.setLngLat(coordinates).setHTML(description).addTo(this.map);
                    });
                    this.map.on('mouseleave', 'places', () => {
                        this.map.getCanvas().style.cursor = '';
                        popup.remove();
                    });
                    this.map.on('moveend', () => {
                        // console.log(this.map.getBounds())
                        let bounds = this.map.getBounds().toArray()
                        // console.log(bounds)
                        this.updateMapData(bounds[0][0], bounds[0][1], bounds[1][0], bounds[1][1], '')
                    });

                    this.map.on('click', 'clusters', (e) => {
                        const features = this.map.queryRenderedFeatures(e.point, {
                            layers: ['clusters']
                        });
                        const clusterId = features[0].properties.cluster_id;
                        this.map.getSource('places').getClusterExpansionZoom(
                            clusterId,
                            (err, zoom) => {
                                if (err) return;
                                this.map.easeTo({
                                    center: features[0].geometry.coordinates,
                                    zoom: zoom
                                });
                            }
                        );
                    });
                    this.map.on('mouseenter', 'clusters', () => {
                        this.map.getCanvas().style.cursor = 'pointer';
                    });
                    this.map.on('mouseleave', 'clusters', () => {
                        this.map.getCanvas().style.cursor = '';
                    });
                    const universityGeoJson = [
                        {
                            type: 'Feature',
                            properties: {
                                message: 'Foo',
                                iconSize: [60, 60]
                            },
                            geometry: {
                                type: 'Point',
                                coordinates: [-79.39564568881434, 43.662946029739814 ]
                            }
                        },
                    ];
                    // 添加地标图片
                    // for (const marker of universityGeoJson) {
                    //     const el = document.createElement('div');
                    //     const width = marker.properties.iconSize[0];
                    //     const height = marker.properties.iconSize[1];
                    //     el.className = 'marker';
                    //     el.style.backgroundImage = `url(https://placekitten.com/g/${width}/${height}/)`;
                    //     el.style.width = `${width}px`;
                    //     el.style.height = `${height}px`;
                    //     el.style.backgroundSize = '100%';
                        
                    //     el.addEventListener('click', () => {
                    //         window.alert(marker.properties.message);
                    //     });
                        
                    //     // Add markers to the map.
                    //     new mapboxgl.Marker(el)
                    //         .setLngLat(marker.geometry.coordinates)
                    //         .addTo(this.map);
                    // }

                });
                this.map.addControl(new mapboxgl.NavigationControl());
            },
            searchMapData() {
                fetch('http://118.195.179.89:8100/api/bread/landing/map/search', {
                    method: 'post',
                    headers: {'Content-type': 'application/json'},
                    body: JSON.stringify({
                        searchText: this.searchContent,
                    })
                })
                .then(res => res.json())
                .then((response) => {
                    this.geoJson = response.data.geoJson.data
                    this.houses = response.data.data
                    this.houseTotal = response.data.total
                })
            },
            fetchMapData() {
                fetch('http://118.195.179.89:8100/api/bread/landing/map', {
                    method: 'post',
                    headers: {'Content-type': 'application/json'},
                    body: JSON.stringify({
                        southwestLat: '43.65303603792918',
                        southwestLng: '-79.55698245533017',
                        northeastLat: '43.7406087450519',
                        northeastLng: '-79.21709292896314',
                    })
                })
                .then(res => res.json())
                .then((response) => {
                    this.geoJson = response.data.geoJson.data
                    this.houses = response.data.data
                    this.houseTotal = response.data.total
                    // console.log( this.geoJson )
                    // const geojsonSource = this.map.getSource('places')
                    // geojsonSource.setData(this.geoJson)
                    this.createMap()
                })
            },
            updateMapData(swlng, swlat, nelng, nelat, sr) {
                // console.log(nelat, swlat, nelng, swlng)
                fetch('http://118.195.179.89:8100/api/bread/landing/map', {
                    method: 'post',
                    headers: {'Content-type': 'application/json'},
                   
                    body: JSON.stringify({
                        southwestLat: swlat.toString(),
                        southwestLng: swlng.toString(),
                        northeastLat: nelat.toString(),
                        northeastLng: nelng.toString(),
                        sr: sr
                    })
                })
                .then(res => res.json())
                .then((response) => {
                    this.geoJson = response.data.geoJson.data
                    // console.log( JSON.stringify(this.geoJson))
                    const bounds = this.map.getBounds()
                    console.log(bounds)
                    const geojsonSource = this.map.getSource('places')
                    geojsonSource.setData(this.geoJson)

                    this.houses = response.data.data
                    this.houseTotal = response.data.total
                    // geojsonSource.setData({
                    //         "type": "FeatureCollection",
                    //         "features": [{
                    //             "type": "Feature",
                    //             "properties": {"name": "Null Island"},
                    //             "geometry": {
                    //                 "type": "Point",
                    //                 "coordinates": [ -79.3921129, 43.6450134 ]
                    //             }
                    //         }]
                    // })
                })
            },
            onTypeChanged(modelValue) {
                console.log(modelValue)
                console.log("12121212")
                let bounds = this.map.getBounds().toArray()
                this.updateMapData(bounds[0][0], bounds[0][1], bounds[1][0], bounds[1][1], modelValue.value)
            },
            onSearchContentChanged(modelValue) {
                console.log(modelValue)
                this.searchContent = modelValue
            }
        },

        mounted() {
           this.fetchMapData()
        },
      
        data() {
            return {
                searchContent: '',
                map: {},
                geoJson: '{}',
                selecteWuye: [
                    {
                        state: 'All',
                        value: "",
                    },
                    {
                        state: 'Sale',
                        value: 'Sale',
                    },
                    {
                        state: 'Lease',
                        value: 'Lease',
                    },
                ],
                selecteState: [
                    {
                        state: '出售',
                        value: '1',
                    },
                    {
                        state: '出租',
                        value: '2',
                    },
                    {
                        state: '售出',
                        value: '3',
                    },
                    {
                        state: '租出',
                        value: '4',
                    },
                ],
                showMap: true,
                switchBtnText1: 'List',
                switchBtnText2: 'Map',
                listCol: 4,
                listColFull: 12,
                listSm: 6,
                listSmFull: 2,
                houses: [],
                houseTotal: 0,
            }
        },
    }
</script>

<style>
#map {
  height: 80vh;
}
.selected-text-des {
    margin-top: 15px;
    margin-left:40px;
}
.selected-bar-top {
    margin-top: 3px;
}
.selected-card {
    height: 80vh !important;
    margin-left: 10px;
    overflow-y: auto;
}
.selected-select {
    margin-top: 17px;
}
</style>