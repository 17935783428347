<template>
    <v-col>
        <v-card
            class="mx-auto"
            max-width="500"
            >
            <v-card-title class="text-h6 font-weight-regular justify-space-between">
                <span>{{ currentTitle }}</span>
            </v-card-title>

            <v-window>
                <v-window-item>
                    <v-card-text>
                                <v-text-field
                                    v-model="emailModel"
                                    label="Email"
                                    placeholder="john@google.com"
                                    required
                                ></v-text-field>
                                <v-text-field
                                    v-model="passwordModel"
                                    label="Password"
                                    type="password"
                                    required
                                ></v-text-field>
                    </v-card-text>
                    <v-card-actions>
                            <v-btn
                                variant="text"
                                @click="$emit('hideLogin')"
                            >
                                Close
                            </v-btn>
                            <v-spacer></v-spacer>
                            <v-btn
                                color="#A7121D"
                                variant="tonal"
                                @click="$emit('doLogin',emailModel, passwordModel)"
                            >
                                Login
                            </v-btn>
                    </v-card-actions>
                </v-window-item>
            </v-window>
        </v-card>
    </v-col>
</template>

<script>

export default {
    data() {
        return {
            currentTitle: 'Login',
            emailModel: '',
            passwordModel: '',
        }
    }
}

</script>

<style>
</style>