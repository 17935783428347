<template>
    <v-container fluid>
        <SaleCard :condo="condo"/>
    </v-container>
</template>

<script>
    import SaleCard from '@/components/SaleCard'
    export default {
        name: 'HomeSale',
        props: ['condo'],
        components: {
            SaleCard,
        }
    }
</script>

<style>
</style>