<template>
    <NavBar />
    <v-btn
        depressed
        color="red-darken-2"
        style="margin-top: 50px; margin-left: 40px"
        @click="this.$router.push('/')"
        >
        Back
    </v-btn>
    <v-row style="padding-top: 20px">
        <v-col>
            <v-card class="selected-card" border flat>
                <v-row class="selected-text-des">A total of<div style="color: #A7121D">{{ houseTotal }}</div> istings were found for you, the current page only displays 50 listings</v-row>
                <v-row no-gutters>
                    <v-col
                        v-for="house, i in houses.length > 50 ? houses.slice(0, 50) : houses"
                        :key="i"
                        cols="12"
                        sm="2"
                        >
                        <MapListHouseCard :house="house"/>
                    </v-col>
                </v-row>
            </v-card>
        </v-col>
    </v-row>
</template>


<script>
import NavBar from '@/components/Navbar.vue'
import MapListHouseCard from '@/components/MapListHouseCard.vue';
export default {
    components:{
        NavBar,
        MapListHouseCard,
    },
    data() {
        return {
            houses: [],
            houseTotal: 0,
        }
    },
    methods: {
        searchMapData() {
                fetch('http://118.195.179.89:8100/api/bread/landing/map/search', {
                    method: 'post',
                    headers: {'Content-type': 'application/json'},
                    body: JSON.stringify({
                        searchText: this.$route.query.searchText,
                    })
                })
                .then(res => res.json())
                .then((response) => {
                    this.houses = response.data.data
                    this.houseTotal = response.data.total
                })
        },
    },
    mounted() {
        console.log(this.$route.query.searchText)
        this.searchMapData()
    },
}
</script>

<style>
</style>