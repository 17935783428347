<template>
  <div class="login-container">
    <h2><img src="./assets/logo.png" alt="Breadrent"></h2>
    <form>
      <label for="email">Email</label>
      <input type="email" id="email" name="email" required>
      <label for="password">Password</label>
      <input type="password" id="password" name="password" required>
      <button type="submit">Login</button>
    </form>
  </div>
</template>

<style scoped>
h2 {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
  font-size: 2rem;
}

h2 img {
  height: 2rem;
  margin-right: 0.5rem;
}

form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: bold;
}

input {
  padding: 0.5rem;
  border-radius: 30px;
  border: 1px solid #ccc;
  margin-bottom: 1rem;
  width: 100%;
}

button {
  background-color: #4caf50;
  color: #fff;
  padding: 0.5rem 1rem;
  border-radius: 30px;
  border: none;
  cursor: pointer;
}
</style>
