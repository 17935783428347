<template>
        <v-app-bar 
            color="#ffffff" 
            app
            dark
            class="px-16" 
            >
            <v-btn icon size="large" color="red-darken-2">
                Bread Rent
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn text @click="this.$router.push('/')">Home</v-btn>
            <!-- <v-btn text @click="scroll('home')">找房</v-btn> -->
            <v-btn text @click="this.$router.push('/maplist')">Map</v-btn>
            <!-- <v-btn text @click="scroll('lease')">租房</v-btn> -->
            <!-- <v-btn text @click="this.$router.push('/louhua')">楼花</v-btn> -->
            <v-divider
                class="mx-4 dark-red-text"
                vertical
                />

            <!-- <v-btn text @click="this.$router.push('/register')">注册</v-btn> -->
            <v-btn text v-show="!isLogin" @click="$emit('showLogin')">Login</v-btn> 
            <v-avatar
                v-show="isLogin"
                color="indigo"
                size="36"
                >
                <span class="white--text text-h5">U</span>
            </v-avatar>
        </v-app-bar>
 
</template>

<script>

export default {

    props: [
        'loginState'
    ],

    components: {

    },
    methods: {
        data() {
            return {
                dialog: false,
                agreement: false,
            }
        },
        scroll(refName)  {
            const element = document.getElementById(refName);
            element.scrollIntoView({behavior: "smooth"});
        }
    },
    computed: {
        isLogin() {
            return this.loginState
        }
    }
}
</script>

<style>
</style>