<template>
    <v-card
        @click="openInNewWindow()"
        :loading="loading"
        class="mx-auto my-12"
        max-width="280"
        >
        <v-carousel hide-delimiters show-arrows="hover" height="200px">
            <v-carousel-item
                cover
                v-for="i in house.urlSize"
                :key="i"
                :src="'http://www.breadrent.com/CondoMedia/' + house.mlNum + '/' + (i+1) + '.jpeg'"
                >
            </v-carousel-item>
        </v-carousel>

        <v-card-title>{{ house.addr }}</v-card-title>

        <v-card-text>
            <v-row
                align="center"
                class="mx-0"
                style="color:#808080"
                >
                <div style="margin-top: 2px">MLS@</div> {{ house.mlNum }}
            </v-row>
            <v-row
                align="center"
                class="mx-0 text-subtitle-1"
                >
                <div style="color:#A7121D"> CAD$ <p style="padding-left: 2px">{{ format_with_substring(house.lpDol) }}</p> </div>
            </v-row>
            <br />
            <v-row class="mx-0 text-subtitle-1" style="margin-top: 3px">
                {{ house.br }}<v-icon icon="mdi-bed" style="margin-left: 5px"></v-icon>
                <div style="margin-left: 5px">{{ house.bathTot }}</div><v-icon icon="mdi-paper-roll" style="margin-left: 5px"></v-icon>
                <div style="margin-left: 5px">{{ house.totParkSpcs }}</div><v-icon icon="mdi-car" style="margin-left: 5px"></v-icon>
                <div style="padding-left: 10px; color: #A7121D;"> {{ house.sr }}</div>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
    export default {
        props: ['house', 'loading'],
        data() {
            return {
                suffix: 'CondoMedia',
            }
        },
        methods: {
            format_with_substring(number) {
                // 数字转为字符串，并按照 .分割
                let arr = (number + '').split('.');
                let int = arr[0] + '';
                let fraction = arr[1] || '';
                // 多余的位数
                let f = int.length % 3;
                // 获取多余的位数，f可能是0， 即r可能是空字符串
                let r = int.substring(0, f);
                // 每三位添加','金额对应的字符
                for (let i = 0; i < Math.floor(int.length / 3); i++) {
                    r += ',' + int.substring(f + i * 3, f + (i + 1) * 3);
                }
                // 多余的位数，上面
                if (f === 0) {
                    r = r.substring(1);
                }
                // 调整部分和小数部分拼接
                return r + (!!fraction ? '.' + fraction : '');
            },
            openInNewWindow() {
                let routerData = this.$router.resolve({path: '/condo/detail'}); 
                window.open(routerData.href, '_blank');
            }

        }

    }
</script>

<style>
.maplist-mls {
    text-decoration-color: #181818;
}
</style>