<template>
    <Navbar />
    <v-row style="margin-top: 20px">
        <v-col cols="3"></v-col>
        <v-col
            cols="6"
            align="center" justify="center"
            >
            <v-card
                col="4"
                class="mx-auto"
                style="max-width: 500px;"
                >
                <v-form
                    ref="form"
                    v-model="form"
                    class="pa-4 pt-6"
                    >
                    <v-text-field
                        v-model="phone"
                        variant="filled"
                        color="#A7121D"
                        label="Phone number"
                    ></v-text-field>
                    <v-text-field
                        v-model="email"
                        :rules="[rules.email]"
                        variant="filled"
                        color="#A7121D"
                        label="Email"
                        type="email"
                    ></v-text-field>
                    <v-text-field
                        v-model="password"
                        :rules="[rules.password, rules.length(6)]"
                        variant="filled"
                        color="#A7121D"
                        counter="6"
                        label="Password"
                        style="min-height: 96px"
                        type="password"
                    ></v-text-field>
                    <v-checkbox
                        v-model="agreement"
                        :rules="[rules.required]"
                        color="deep-purple"
                        >
                        <template v-slot:label>
                        I agree&nbsp;
                        <a
                            href="#"
                            @click.stop.prevent="dialog = true"
                        >Terms of Service</a>
                        &nbsp;和&nbsp;
                        <a
                            href="#"
                            @click.stop.prevent="dialog = true"
                        >Privacy Agreement</a>*
                        </template>
                    </v-checkbox>
                </v-form>
                    <v-dialog
                        v-model="dialog"
                        max-width="400"
                        persistent
                        >
                        <v-card>
                            <v-card-title class="text-h5 bg-grey-lighten-3">
                            Legal
                            </v-card-title>
                            <v-card-text>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                            </v-card-text>
                            <v-divider></v-divider>
                            <v-card-actions>
                            <v-btn
                                variant="text"
                                @click="agreement = false, dialog = false"
                            >
                                No
                            </v-btn>
                            <v-spacer></v-spacer>
                            <v-btn
                                color="deep-purple"
                                variant="tonal"
                                @click="agreement = true, dialog = false"
                            >
                                Yes
                            </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>    
            </v-card>
        </v-col>
        <v-col cols="3"></v-col>
    </v-row>

    

</template>

<script>

import Navbar from '@/components/Navbar'

export default {

    components: {
        Navbar,
    },

    data: () => ({
      agreement: false,
      dialog: false,
      email: undefined,
      form: false,
      isLoading: false,
      password: undefined,
      phone: undefined,
      rules: {
        email: v => !!(v || '').match(/@/) || 'Please enter a valid email',
        length: len => v => (v || '').length >= len || `Invalid character length, required ${len}`,
        password: v => !!(v || '').match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*(_|[^\w])).+$/) ||
          'Password must contain an upper case letter, a numeric character, and a special character',
        required: v => !!v || 'This field is required',
      },
    }),
  }
</script>