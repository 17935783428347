// Styles
import '@fortawesome/fontawesome-free/css/all.css';
import 'material-design-icons-iconfont/dist/material-design-icons.css';
import '@mdi/font/css/materialdesignicons.css';
import 'vuetify/styles';
import 'vuetify/dist/vuetify.min.css'
import { createVuetify } from 'vuetify';
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'


const vuetify = createVuetify({
  components,
  directives,
  icons: {
    iconfont: 'fa' || 'md' || 'mdi'
  },
  theme: {
    themes: {
      dark: {
        background: '#ff000000'
      }
    }
  }}
);

export default vuetify


