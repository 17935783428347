import { createApp } from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import { createRouter, createWebHistory } from 'vue-router'
import Home from '@/views/Home'
import LouhuaTest from '@/views/LouhuaTest'
import MapList from '@/views/MapList'
import CondoDetail from '@/views/CondoDetail'
import Register from '@/views/Register'
import SearchResult from '@/views/SearchResult'
import 'mapbox-gl/dist/mapbox-gl.css'

loadFonts()

const routes = [
  {
    path: '/',
    component: Home // 首页
  },
  {
    path: '/register',
    component: Register
  },
  {
    path: '/louhua',
    component: LouhuaTest // 楼花
  },
  {
    path: '/maplist',
    component: MapList // 地图列表
  },
  {
    path: '/condo/detail',
    component: CondoDetail // 公寓详情
  },
  {
    name: 'search',
    path: '/search',
    component: SearchResult // 搜索展示
  }
]

const router = createRouter({
  history: createWebHistory(),
  // base: '/landing/',
  routes, 
})

createApp(App)
  .use(router)
  .use(vuetify)
  .mount('#app')
