import { mdiBed } from '@mdi/js';
import Carousel3d from 'vue-carousel-3d';
<template>
    
    <Navbar />

    <v-btn @click="this.generatePDF()">
  Button
</v-btn>
    <!-- 房源图片轮播 -->
    <v-carousel cycle="true">
        <v-carousel-item src="https://img.realmaster.com/cda/828.1673367959/914/25174914_1.jpg" cover></v-carousel-item>

        <v-carousel-item src="https://img.realmaster.com/cda/828.1673367959/914/25174914_2.jpg" cover></v-carousel-item>

        <v-carousel-item src="https://img.realmaster.com/cda/828.1673367959/914/25174914_3.jpg" cover></v-carousel-item>
    </v-carousel>

    <br>

    <v-row>
        <v-col cols="2">
        </v-col>
        <v-col cols="5">

            <v-card class="mx-auto">

                <v-card-text>
                    <p style="font-size: 30px; font-weight: bold; color: red;">CAD$<span>6,500</span><span>出租</span></p>
                    <br>
                    <p style="color: grey; font-size: 18px;"> <span>4202</span><span>65 St Mary St</span></p>
                    <br>
                    <p style="color: grey; font-size: 18px;"><span>Toronto</span>,
                        <span>Ontario</span><span>M5S0A6</span>
                    </p>
                    <br>
                    <p><span>0</span> 在市天数(<span>20230110</span>)</p>
                    <br>
                    <p> <span style="color: red;"><v-icon icon="mdi-home" /> <span>2</span> <v-icon icon="mdi-home" />
                            <span>2</span><v-icon icon="mdi-home" /> <span>1</span> </span> | <span>1400-1599</span>
                        sqft</p>
                </v-card-text>
            </v-card>

            <br>

            <v-card class="mx-auto">

                <v-card-text>
                    <p><span style="font-size: 20px; font-weight: bold; color: red;">概要</span></p>

                    <br>

                    <p><span style="font-size: 15px; font-weight: bold;">ID</span><span
                            style="float: right;">C5858715</span></p>

                    <br>

                    <p><span style="font-size: 15px; font-weight: bold;">状态</span><span style="float: right;">Current
                            Listing</span></p>

                    <br>


                    <p><span style="font-size: 15px; font-weight: bold;">产权</span><span style="float: right;">Current
                            Listing</span></p>

                    <br>

                    <p><span style="font-size: 15px; font-weight: bold;">类型</span><span style="float: right;">民宅
                            公寓</span></p>

                    <br>

                    <p><span style="font-size: 15px; font-weight: bold;">房间</span><span
                            style="float: right;">卧房:2+1,厨房:1,浴室:2</span></p>

                    <br>

                    <p><span style="font-size: 15px; font-weight: bold;">房龄</span><span style="float: right;">0-5</span>
                    </p>

                    <br>
                    <p><span style="font-size: 15px; font-weight: bold;">管理费（月）</span><span
                            style="float: right;">276.72</span></p>

                    <br>
                    <p><span style="font-size: 15px; font-weight: bold;">管理费类型</span><span
                            style="float: right;">保险,取暖,Landscaping,其他,See Remarks,水</span></p>

                    <br>
                    <p><span style="font-size: 15px; font-weight: bold;">交接日期</span><span
                            style="float: right;">即刻入住</span></p>

                    <br>
                    <p><span style="font-size: 15px; font-weight: bold;">挂盘公司</span><span style="float: right;">HOMELIFE
                            NEW WORLD REALTY INC., BROKERAGE</span></p>

                    <br>
                </v-card-text>

            </v-card>

            <br>

            <v-card class="mx-auto">
                <v-card-text>
                    <p><span style="font-size: 20px; font-weight: bold; color: red;">详细</span></p>

                    <br>
                    <p><span style="font-size: 18px; font-weight: bold;">建筑</span></p>

                    <br>

                    <p><span style="font-size: 15px; font-weight: bold;">浴室数量</span><span style="float: right;">1</span>
                    </p>

                    <br>

                    <p><span style="font-size: 15px; font-weight: bold;">卧室数量</span><span style="float: right;">1</span>
                    </p>

                    <br>


                    <p><span style="font-size: 15px; font-weight: bold;">空调</span><span
                            style="float: right;">中央空调</span></p>

                    <br>

                    <p><span style="font-size: 15px; font-weight: bold;">外墙</span><span
                            style="float: right;">砖，水泥</span></p>

                    <br>

                    <p><span style="font-size: 15px; font-weight: bold;">壁炉</span><span style="float: right;">无</span>
                    </p>

                    <br>

                    <p><span style="font-size: 15px; font-weight: bold;">供暖方式</span><span
                            style="float: right;">天燃气</span>
                    </p>

                    <br>
                    <p><span style="font-size: 15px; font-weight: bold;">供暖类型</span><span
                            style="float: right;">压力热风</span></p>

                    <br>
                    <p><span style="font-size: 15px; font-weight: bold;">使用面积</span><span
                            style="float: right;">N/A</span></p>

                    <br>

                    <p><span style="font-size: 20px; font-weight: bold;">土地</span></p>

                    <br>
                    <p><span style="font-size: 15px; font-weight: bold;">面积</span><span
                            style="float: right;">false</span></p>

                    <br>
                    <p><span style="font-size: 20px; font-weight: bold;">周边</span></p>

                    <br>
                    <p><span style="font-size: 15px; font-weight: bold;">社区特点</span><span style="float: right;">Pets not
                            Allowed</span></p>

                    <br>
                    <p><span style="font-size: 15px; font-weight: bold;">设施</span><span
                            style="float: right;">医院,公园,参拜地,公交,周边学校,购物</span></p>

                    <br>
                    <p><span style="font-size: 20px; font-weight: bold;">其他</span></p>

                    <br>
                    <p><span style="font-size: 15px; font-weight: bold;">特点</span><span style="float: right;">Southern
                            exposure,Corner Site,Park/reserve,阳台</span></p>

                    <br>
                    <p><span style="font-size: 15px; font-weight: bold;">地下室</span><span style="float: right;">无</span>
                    </p>

                    <br>
                    <p><span style="font-size: 15px; font-weight: bold;">设施</span><span
                            style="float: right;">医院,公园,参拜地,公交,周边学校,购物</span></p>

                    <br>
                    <p><span style="font-size: 15px; font-weight: bold;">阳台</span><span
                            style="float: right;">Terr</span></p>

                    <br>
                    <p><span style="font-size: 15px; font-weight: bold;">储藏室</span><span
                            style="float: right;">None</span></p>

                    <br>
                    <p><span style="font-size: 15px; font-weight: bold;">壁炉</span><span style="float: right;">N</span>
                    </p>

                    <br>
                    <p><span style="font-size: 15px; font-weight: bold;">空调</span><span
                            style="float: right;">中央空调</span></p>

                    <br>
                    <p><span style="font-size: 15px; font-weight: bold;">供暖</span><span
                            style="float: right;">压力热风</span></p>

                    <br>
                    <p><span style="font-size: 15px; font-weight: bold;">电视</span><span style="float: right;">N</span>
                    </p>

                    <br>
                    <p><span style="font-size: 15px; font-weight: bold;">楼层</span><span style="float: right;">9</span>
                    </p>

                    <br>
                    <p><span style="font-size: 15px; font-weight: bold;">房号</span><span style="float: right;">903</span>
                    </p>

                    <br>
                    <p><span style="font-size: 15px; font-weight: bold;">朝向</span><span style="float: right;">南</span>
                    </p>

                    <br>
                    <p><span style="font-size: 15px; font-weight: bold;">车位</span><span
                            style="float: right;">None</span></p>

                    <br>
                    <p><span style="font-size: 15px; font-weight: bold;">COPR #</span><span
                            style="float: right;">FCC86</span></p>

                    <br>
                    <p><span style="font-size: 15px; font-weight: bold;">物业管理</span><span
                            style="float: right;">Alwington Communites</span></p>

                    <br>
                </v-card-text>

            </v-card>
            <br>
            <v-card>
                <v-card-text>
                    <p><span style="font-size: 20px; font-weight: bold; color: red;">附注</span></p>

                    <br>
                    <p style="font-size: 16px">Welcome To This Cozy And Modern, Handicap Accessible Designed, 1 Bedroom,
                        1 Bath Condo Unit Located In Kingston's Williamsville Just A Few Minutes' Walk To 'The Hub' And
                        Downtown. Offering Convenience And Location - The Unit Offers A Stunning Southern Exposure With
                        Views Looking Out Over Queen's University All The Way To The Water. These Stunning Views Can Be
                        Enjoyed From Both In The Unit Or The Patio Space Accessible From Both Bedroom And Living Room
                        Alike. This Unit Had Been Designed With Wide Doorways For Ease Of Access, Has A Tiled Entrance
                        And Bath, 9 Ft Ceilings, In-Suite Laundry, Stainless Steel Appliances In The Kitchen, Stone
                        Countertops, Contemporary Cabinetry, And A Dining/ Study Nook. Furnishings Are Included For A
                        Quick And Seamless Move-In Or A Turn-Key Investment. Condo Amenities Include A Fitness Room,
                        Party Room, And A Rooftop Garden/Deck. Located On A Public Transit Route, Enjoy Quick Access To
                        Queen's University, St Lawrence College, Hospitals And More. Get In Touch For A Private Showing
                        Today!
                    </p>
                    <br>
                    <p style="font-size: 13px">The listing data is provided under copyright by the Toronto Real Estate
                        Board. The listing data is deemed reliable but is not guaranteed accurate by the Toronto Real
                        Estate Board nor RealMaster.</p>
                </v-card-text>
            </v-card>

            <br>
            <v-card>
                <v-card-text>
                    <p><span style="font-size: 20px; font-weight: bold;color: red;">位置</span><span
                            style="float: right;">打开地图<span style="color: blue;"><v-icon icon="mdi-home" /></span>
                        </span></p>

                    <br>
                    <v-row>
                        <v-col cols="6"><span style="font-size: 15px; font-weight: bold;">省：</span></v-col>
                        <v-col cols="6"><span>Ontario</span></v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="6"><span style="font-size: 15px; font-weight: bold;">城市：</span></v-col>
                        <v-col cols="6"><span>Kingston</span></v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="6"><span style="font-size: 15px; font-weight: bold;">社区：</span></v-col>
                        <v-col cols="6"><span>Central City East</span></v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="6"><span style="font-size: 15px; font-weight: bold;">交叉路口：</span></v-col>
                        <v-col cols="6"><span>Princess St And Victoria St</span></v-col>
                    </v-row>


                    <v-row>

                        <v-col cols="4"><v-card variant="outlined" style="text-align:center"> <v-card-text>
                                    <span style="color: blue;">街景<v-icon icon="mdi-home" /></span>
                                </v-card-text></v-card></v-col>
                        <v-col cols="4"><v-card variant="outlined" style="text-align:center"> <v-card-text>
                                    <span style="color: blue;">周边设施<v-icon icon="mdi-home" /></span>
                                </v-card-text></v-card></v-col>
                        <v-col cols="4"><v-card variant="outlined" style="text-align:center"> <v-card-text>
                                    <span style="color: blue;">社区<v-icon icon="mdi-home" /></span>
                                </v-card-text></v-card></v-col>

                    </v-row>

                </v-card-text>
            </v-card>

            <br>




        </v-col>
        <!-- 预约看房 -->
        <v-col cols="3">
            <v-card class="mx-auto" fixed>
                <template v-slot:title>
                    预约看房
                </template>

                <v-card-text>
                    <v-row>
                        <v-col cols="6"> <v-text-field label="名字">
                            </v-text-field></v-col>
                        <v-col cols="6"><v-text-field label="姓">
                            </v-text-field></v-col>
                    </v-row>

                    <br>
                    <v-text-field label="邮箱">
                    </v-text-field>
                    <br>
                    <v-text-field label="手机">
                    </v-text-field>
                    <br>
                    <v-row>
                        <v-col cols="6"> <v-text-field label="ID">
                            </v-text-field></v-col>
                        <v-col cols="6"> <v-text-field label="微信">
                            </v-text-field></v-col>
                    </v-row>
                    <br>
                    <v-btn class="left" tile color="#A7121D" dark>
                        <div class="white-text">提交</div>
                    </v-btn>
                </v-card-text>

            </v-card>
        </v-col>
    </v-row>

   <MoreFilter/>
</template>

<script>
import MoreFilter from '@/components/MoreFilter'
import Navbar from '@/components/Navbar'
import jsPDF from 'jspdf';
export default {
    name: 'CondoDetail',
    components:{MoreFilter, Navbar},
    methods: {
    generatePDF() {
        
        const doc = new jsPDF({
        orientation: 'portrait',
        unit: 'mm',
        format: 'a4'
      });

      const pageWidth = doc.internal.pageSize.width - 40;
      

      doc.text('概要', 20, 20);

      // ID aligned to the left
      doc.text('ID编号:', 20, 30);
      doc.line(28, 25, 28, 30); // vertical line
      doc.text('N5917955', 35, 30, { align: 'left', width: pageWidth - 35 });

      // Other properties aligned to the right
      doc.text('Status:', 20, 40);
      doc.line(60, 35, 60, 40); // vertical line
      doc.text('Current Listing', 67, 40, { align: 'left', width: pageWidth - 67 });

      doc.text('DOM:', 20, 50);
      doc.line(35, 45, 35, 50); // vertical line
      doc.text('1 Days', 42, 50, { align: 'left', width: pageWidth - 42 });

      doc.text('Ownership Type:', 20, 60);
      doc.line(92, 60, 92, 68); // vertical line
      doc.text('Freehold', 99, 60, { align: 'left', width: pageWidth - 99 });

      doc.text('Type:', 20, 70);
      doc.line(48, 70, 48, 78); // vertical line
      doc.text('Residential House, Detached', 55, 70, { align: 'left', width: pageWidth - 55 });

      doc.text('Rooms:', 20, 80);
      doc.line(50, 80, 50, 88); // vertical line
      doc.text('Bed:5+3, Kitchen:3, Bath:6', 57, 80, { align: 'left', width: pageWidth - 57 });

      doc.text('Square Footage:', 20, 90);
      doc.line(70, 90, 70, 98); // vertical line
      doc.text('3500-5000 sqft', 77, 90, { align: 'left', width: pageWidth - 77 });

      doc.text('Parking:', 20, 100);
      doc.line(48, 100, 48, 108); // vertical line
      doc.text('3 (15) Attached +12', 55, 100, { align: 'left', width: pageWidth - 55 });

      doc.text('Age:', 20, 110);
      doc.line(28, 110, 28, 118); // vertical line
      doc.text('TBA', 35, 110, { align: 'left', width: pageWidth - 35 });

      doc.text('Listing Courtesy of AVION REALTY INC., BROKERAGE', 20, 120);
      doc.save('example.pdf');
    }
  }
    , data() {
        return {
           
        }
    }
}
</script>