<template>
   <v-card
    class="mx-auto"
    max-width="344"
    @click="openInNewWindow()"
    >
    <!-- <v-img
      src="https://cdn.vuetifyjs.com/images/cards/sunshine.jpg"
      height="200px"
      >
    </v-img> -->
    <v-carousel hide-delimiters show-arrows="hover" height="200px">
        <v-carousel-item
            cover
            v-for="(item,i) in condo.items "
            :key="i"
            :src="item"
            >
        </v-carousel-item>
    </v-carousel>
    <v-divider dark></v-divider>
    <v-card-title>
      {{ condo.mlNum}}
    </v-card-title>

    <v-card-subtitle>
      {{ condo.addr }}
    </v-card-subtitle>

    <v-card-actions>
      <v-btn
        color="red lighten-2"
        text
      >
        {{ condo.lpDol}}
      </v-btn>

      <v-spacer></v-spacer>

      <!-- <v-btn
        icon
        @click="show = !show"
      >
        <v-icon>{{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
      </v-btn> -->
    </v-card-actions>

    <!-- <v-expand-transition>
      <div v-show="show">
        <v-divider></v-divider>

        <v-card-text>
        </v-card-text>
      </div>
    </v-expand-transition> -->
  </v-card>
</template>

<script>
    export default {
        name: 'SaleCard',
        props: ['condo'],
        data() {
            return {
                show: false,
            }
        },
        methods: {
          openInNewWindow() {
            let routerData = this.$router.resolve({path: '/condo/detail'}); 
            window.open(routerData.href, '_blank');
          }
        }
    }
</script>

<style>
</style>